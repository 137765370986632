<template>
    <div class="w-full flex justify-center items-center py-20 relative">
        <img class="absolute w-full h-full -z-1" src="../../../../assets/public/images/see-bg-shape.png"/>
        <div class="max-w-7xl flex flex-col items-center justify-center w-full z-10">
            <TextWithBorder :content="title" size="4xl" weight="bold" color="gray-600" align="center" class="space-y-3 w-full md:w-700"/>
            <div class="max-w-3xl mt-5 mb-20">
                <Text :content="description" size="md" weight="" color="gray-500" class="w-full text-center"/>
            </div>
            <div class="relative w-full flex justify-center items-center">
                <img class="absolute left-28 top-5 w-20 hidden lg:block" src="../../../../assets/public/images/bg-bubble-one.png" style="z-index: -1"/>
                <VideoView :video_id="video_id"/>
                <img class="absolute right-28 bottom-5 w-16 hidden lg:block" src="../../../../assets/public/images/bg-bubble-three.png" style="z-index: -1"/>
            </div>
            
        </div>
    </div>
</template>

<script>
import TextWithBorder from '../../../molecules/Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
import Text from '../../../atoms/Text/Text.vue'
import VideoView from '../../../molecules/Content/VideoViewWithModal/VideoViewWithModal.vue'
/**
 - Use it to show a content with title, description and video
 **/
export default {
    components: {
        TextWithBorder,
        Text,
        VideoView
    },
    props: {
        /**
         * title
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * title
         */
        description: {
            type: String,
            default: ''
        },
        /**
         * youtube video id
         */
        video_id: {
            type: String,
            default: '3nccfSTEUXY'
        }
    }
};
</script>
