<template>
  <div>
    <Header v-bind="header" @goHome="() => $emit('goHome')"/>
    <SubHeader v-bind="subHeader" @action="() => navigateToPage()" @onSignIn="onSignIn"/>
    <CardsContent v-bind="cardsContent"/>
    <LottieContent v-for="(content, id) in lottieContent" :key="id" v-bind="content"/>
    <!-- <VideoContent v-bind="videoContent"/> -->
    <BannerContent v-bind="bannerContent" @action="() => navigateToPage()"/>
    <Testimonial v-bind="testimonial" class="mb-40 xl:mb-80"/>
    <SubFooter v-bind="subFooter" @action="() => navigateToPage()"
               :saveContactRequest="(data) => $emit('saveContactRequest', data)"
               ref="contactForm"
    />
    <Footer v-bind="{
        	...footer,
        	isLoggedIn,
        }" class="mt-20"/>
  </div>

</template>

<script>
import Header from '../../organisms/Headers/SimpleWithNavigation/SimpleWithNavigation.vue'
import SubHeader
  from '../../organisms/HeaderSections/WithTitleDescriptionButtonAndLogo/WithTitleDescriptionButtonAndLogo.vue'
import CardsContent from '../../organisms/ContentSections/WithTitleAndCards/WithTitleAndCards.vue'
import LottieContent
  from '../../organisms/ContentSections/WithTitleAndDescriptionsAndLottie/WithTitleAndDescriptionsAndLottie.vue'
import VideoContent from '../../organisms/ContentSections/WithTitleDescriptionAndVideo/WithTitleDescriptionAndVideo.vue'
import BannerContent from '../../organisms/ContentSections/WithTitleButtonAndCards/WithTitleButtonAndCards.vue'
import Testimonial from '../../organisms/Testimonials/WithMessageCardsAndBanner/WithMessageCardsAndBanner.vue'
import SubFooter
  from '../../organisms/ContentSections/FooterWithImagesAndContactForm/FooterWithImagesAndContactForm.vue'
import Footer from '../../organisms/Footers/SimpleWithSocialIcons/SimpleWithSocialIcons.vue'
import {ref} from "vue";

/**
 - Use it to show a Home template
 **/
export default {
  components: {
    Header,
    SubHeader,
    CardsContent,
    LottieContent,
    VideoContent,
    BannerContent,
    Testimonial,
    SubFooter,
    Footer,
    ref
  },
  props: {
    /**
     * Use it for header
     */
    header: {
      type: Object,
      default: () => {
      },
    },
    /**
     * Use it for sub header
     */
    subHeader: {
      type: Object,
      default: () => {
      }
    },
    /**
     * Use it for cards content
     */
    cardsContent: {
      type: Object,
      default: () => {
      }
    },
    /**
     * Use it for lottie animation content
     */
    lottieContent: {
      type: Array,
      default: () => []
    },
    /**
     * Use it for video content
     */
    videoContent: {
      type: Object,
      default: () => {
      }
    },
    /**
     * Use it for banner content
     */
    bannerContent: {
      type: Object,
      default: () => {
      }
    },
    /**
     * Use it for testimonial content
     */
    testimonial: {
      type: Object,
      default: () => {
      }
    },
    /**
     * Use it for brand footer section
     */
    subFooter: {
      type: Object,
      default: () => {
      }
    },
    /**
     * Use it for footer
     */
    footer: {
      type: Object,
      default: () => {
      }
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    saveContactRequest: {
      type: Function,
      default: () => {
      }
    }
  },
  methods: {
    navigateToPage() {
      this.$router.push(this.state?.currentUser?.id ? '/dashboard' : '/register')
    },
    setContactFormData(data) {
      this.$refs.contactForm.setFormObject(data)
    },
    onSignIn() {
      const url = 'https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=https%3A%2F%2Fwww.faid.co%2F%23%2Fssl-classlink&client_id=c16700000687053f4e2d7ab865991847ad5e733d84b9&response_type=code';
      window.location.href = url
    }
  }
};
</script>
