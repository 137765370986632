<template>
    <div class="w-full mx-auto items-center bg-contact">
        <div class="max-w-7xl mx-auto z-10 pt-16 relative hidden xl:block">
            <img src="../../../../assets/public/images/testimonial-bg.png" class="w-full absolute" style="z-index: -1;">
            <div class="flex flex-col" style="z-index: 10;">
                <div class="flex justify-center items-center">
                    <MessagePanel :message="cards[0].message" :user="cards[0].user" :position="cards[0].position"/>
                </div>
                <div class="flex space-x-10">
                    <MessagePanel :message="cards[1].message" :user="cards[1].user" :position="cards[1].position"/>
                    <MessagePanel :message="cards[2].message" :user="cards[2].user" :position="cards[2].position"/>
                </div>
            </div>
        </div>
        <swiper
            :slides-per-view="1"
            :space-between="50"
            class="block xl:hidden pt-20"
			>
            <swiper-slide v-for="(card, i) in cards" :key="i" >
                <MessagePanel :message="card.message" :user="card.user" :position="card.position"/>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Button from '../../../atoms/Button/Button.vue'
import MessagePanel from '../../../molecules/MessagePanel/MessagePanel.vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.min.css';
/**
 - Use it to show a testimonial with cards and banner
 **/
export default {
    components: {
    Text,
    Button,
    MessagePanel,
    Swiper, SwiperSlide
},
    props: {
        cards: {
            type: Array,
            default: () => []
        }
    }
};
</script>
