<template>
  <div :class="style" class="hidden xl:flex">
    <img :src="require(`../../../assets/public/images/${user.image}`)" class="w-32 h-32 rounded-full border-2 border-primary object-cover bg-gray-50 filter grayscale hover:grayscale-0"/>
    <Card :class="['max-w-lg px-3', cardClass]">
      <Text size="sm" weight="normal" color="gray-500" :content="message" class="italic" />
      <div class="flex items-center space-x-1 mt-2">
        <Text size="xs" weight="bold" color="primary" :content="user.name" />
        <Text size="xs" weight="normal" color="gray-400" :content="`- ${user.role}`" />
      </div>
    </Card>
  </div>
  <div class="block xl:hidden mx-4 pb-5">
    <Card :class="[cardClass, 'shadow-lg']">
      <div class="w-full flex flex-col items-center justify-center">
        <img :src="require(`../../../assets/public/images/${user.image}`)" class="w-24 h-24 rounded-full border-2 border-primary object-cover"/>
        <Text size="xs" weight="bold" color="primary" :content="user.name" class="mt-8"/>
        <Text size="xs" weight="normal" color="gray-400" :content="user.role" class="mt-2"/>
        <Text size="sm" weight="normal" color="gray-500" :content="message" class="italic text-center mt-5 leading-8" />
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '../../atoms/Panels/BasicCard/BasicCard.vue'
import Text from '../../atoms/Text/Text.vue'
import Avatar from '../../atoms/Avatar/Avatar.vue'
import { computed } from 'vue'
/**
 * - Use it to show a simple message panel with user information in various avatar positioned
 */
export default {
  components: {
    Card, Text, Avatar
  },
  props: {
    /**
     * message
     */
    message: {
      type: String,
      default: ''
    },
    /**
     * user object
     */
    user: {
      type: Object,
      default: () => { }
    },
    /**
     * position
     */
    position: {
      type: String,
      default: 'left'
    },
    /**
     * card external class
     */
    cardClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      style: computed(() => {
        if (this.position === 'left') {
          return 'flex items-center justify-center space-x-5'
        } else if (this.position === 'right') {
          return 'flex flex-row-reverse items-center justify-center space-x-5 space-x-reverse'
        } else if (this.position === 'bottom') {
          return 'flex flex-col flex-col-reverse justify-center items-center space-y-5 space-y-reverse'
        } else  {
          return 'flex flex-col flex-col justify-center items-center space-y-5'
        }
      })
    }
  }
};
</script>
