<template>
    <div class="w-full block relative px-2 xl:px-0">
        <div class="max-w-7xl mx-auto bg-contact shadow-lg rounded px-10 py-10 w-full relative bg">
            <img src="../../../../assets/public/images/bg-bubble-one.png" class="hidden xl:block w-20 absolute right-80 top-20"/>
            <img src="../../../../assets/public/images/bg-bubble-three.png" class="hidden xl:block w-20 absolute right-32 bottom-10"/>
            <div class="space-y-5 z-20">
                <TextWithBorder :content="title" weight="semibold" size="4xl" color="gray-600" class="w-80 mb-16"/>
                <div v-for="form in forms" :key="form.label" class="w-full">
                    <Input v-if="form.type === 'string' || form.type === 'email'" :type="form.type" v-model:value="formObject[form.name]" :label="form.label" class="w-full lg:w-1/3"/>
                    <Select v-else-if="form.type === 'select'" :items="form.options" :type="form.type" v-model:selectedItem="formObject[form.name]" :label="form.label" class="w-full lg:w-1/3"/>
                    <Textarea v-else-if="form.type === 'textarea'" :type="form.type" v-model:value="formObject[form.name]" :label="form.label" class="w-full lg:w-3/5"/>
                </div>
                <Button variant="primary" :content="btnText"
                    class="px-16 py-4 font-semibold text-md shadow-lg bg-primary text-white mt-9"
                    :onClick="() => $emit('onClick')" />
                </div>
        </div>
    </div>
</template>

<script>
import TextWithBorder from '../../../molecules/Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
import Text from '../../../atoms/Text/Text.vue'
import VideoView from '../../../molecules/Content/VideoViewWithModal/VideoViewWithModal.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Textarea from '../../../molecules/Textareas/Simple/Simple.vue'
import Button from '../../../atoms/Button/Button.vue'
import { ref } from "vue";
/**
 - Use it to show a contact form with overlapped images
 **/
export default {
    components: {
        TextWithBorder,
        Button,
        Text,
        VideoView,
        Input,
        Select,
        Textarea
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        forms: {
            type: Array,
            default: () => []
        },
        btnText: {
            type: String,
            default: ''
        },
        onClick: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        const formObject = ref({});
        return {
			formObject,
		}
    },
    methods: {
		getFormObject() {
            return this.formObject;
		},
        setFormObject(value) {
            this.formObject = value
        }
    }
};
</script>

<style>
    .bg {
        background-image: url(../../../../assets/public/images/contact-form-bg-one.png);
        background-repeat: no-repeat;
        background-position: right 20% bottom 50%;
        background-size: 67%;
    }
</style>