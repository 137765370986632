
<template>
	<div class="flex justify-center relative max-w-7xl md:max-w-xl">
		<img :src="require(`../../../../assets/public/images/icon_${logoType}.png`)" class="w-20 absolute"/>
		<CardContainer class="w-full flex justify-center items-center mt-16 border pt-10 shadow-lg">
			<Text :content="title" size="xl" weight="bold" color="gray-600" class="text-center"/>
			<div class="bg-primary w-16 h-0.5 mx-auto mt-5 mb-6"></div>
			<Text :content="description" size="md" weight="base" color="gray-500" class="text-center"/>
		</CardContainer>
	</div>
</template>

<script>
	import CardContainer from '../../../atoms/Panels/BasicCard/BasicCard.vue'
	import Text from '../../../atoms/Text/Text.vue'
	/**
	 * - Use it to show card with title, description and overlapped avatar
	 */
	export default {
		components: {
			CardContainer,
			Text,
		},
		props: {
			/**
			 * title
			 */
			title: {
				type: String,
				default: ''
			},
			/**
			 * description
			 */
			description: {
				type: String,
				default: ''
			},
			/**
			 * logo type
			 */
			logoType: {
				type: String,
				default: ''
			}
		}
	}
</script>
