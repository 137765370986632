<template>
  <Template @goHome="() => $router.push('/')" @saveContactRequest="(data) => saveContactRequest(data)" v-bind="{
  	...params,
  	isLoggedIn: state.isLoggedIn
  }" ref="homeRef"/>

</template>

<script>
import Template from '../components/templates/Home/Home'
import {c} from '../components/constants'
import { ref } from "vue";

export default {
  components: {
  	Template,
    ref
  },
  data () {
    return {
    	params: c.home
    }
  },
  methods: {
    async saveContactRequest(data) {
      if (!data?.email?.trim()) {
				this.actions.alert.showError({message: 'Please insert email address!'});
				return false;
			}
			if (!data?.message?.trim()) {
				this.actions.alert.showError({message: 'Please insert message!'});
				return false
			}
      const params = {
        name: 'contact',
        subject: data.message,
        emails: {set: [data?.email]},
        comment: data.message,
      }
      const {saveContactRequest} = await this.actions.user.saveContactRequest({data: params});
      if(saveContactRequest && saveContactRequest.id) {
        this.actions.alert.showSuccess({message: 'Your message send successfully'});
        this.$refs.homeRef.setContactFormData({ email: '', role: null, message: '' })
      }
    }
  }
}
</script>
