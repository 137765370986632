<template>
  <div class="w-full flex justify-center items-center px-4 md:px-0">
    <div class="flex justify-center items-center py-0 sm:py-10 max-w-7xl">
      <div class="grid grid-cols-1 md:grid-cols-3 max-w-7xl mx-4 xl:mx-auto gap-6">
        <div class="col-span-1" :class="contentPosition === 'left' ? 'order-first md:order-first' : 'order-first md:order-last'">
          <TextWithBorder size="3xl" color="gray-700" weight="semibold" :content="title" class="sm:text-4xl space-y-5"/>
          <div class="space-y-5 mt-10">
            <Text v-for="description in descriptions" :key="description" size="md" color="gray-400" class="leading-7" :content="description"/>
          </div>
        </div>
        <div class="col-span-1 md:col-span-2 pl-10 mt-0 md:-mt-40">
          <Vue3Lottie :animationData="logo"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '../../../atoms/Icons/Icons.vue'
import Text from '../../../atoms/Text/Text.vue'
import TextWithBorder from '../../../molecules/Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
import Button from '../../../atoms/Button/Button.vue'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

/**
 * - Use it to show a simple branded header with title, description, button and logo
 */
export default {
  components: {
   Icon, Text, Button, Vue3Lottie, TextWithBorder
  },
  props: {
    /**
     * title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * description
     */
    descriptions: {
      type: Array,
      default: () => []
    },
    /**
     * logo
     */
    logo: {
      type: Object,
      default: () => {}
    },
    /**
     * content position
     */
    contentPosition: {
      type: String,
      default: 'left'
    }
  },
};
</script>
