<template>
    <div class="w-full mx-auto items-center relative custom-bg pb-20">
        <!-- <img src="../../../../assets/public/images/green-background.png" class="absolute w-full h-700 xl:h-auto" style="z-index: -1;"> -->
        <div class="max-w-7xl mx-auto z-10 pt-16">
            <div class="grid grid-cols-1 xl:grid-cols-3 gap-6">
                <div class="col-span-1 order-last xl:order-first flex xl:block flex-col items-center">
                    <Text size="4xl" weight="bold" color="white" :content="title" class="max-w-lg text-center xl:text-left"/>
                    <Text size="2xl" weight="normal" color="white" :content="description" class="mt-7 leading-10 text-center px-6 xl:text-left xl:px-0"/>
                    <!-- <div class="flex space-x-4 mt-12 xl:mt-1">
                        <a href="https://itunes.apple.com/us/app/trustedaid/id1438283203?mt=8&ign-mpt=uo%3D2" target="_blank"><img src="../../../../assets/public/images/app-store-badge-2.png"/></a>
                        <a href="https://play.google.com/store/apps/details?id=com.trustedaid" target="_blank"><img src="../../../../assets/public/images/google-play-badge.png"/></a>
                    </div> -->
                </div>
                <div class="col-span-2 mx-auto xl:mx-0 max-w-lg xl:max-w-7xl">
                    <img src="../../../../assets/public/images/app-mockup.png" class="-mt-40"/>
                </div>
            </div>
            <Button variant="primary" :content="btnText"
                class="uppercase px-16 py-5 font-semibold text-lg shadow-lg bg-white text-primary mt-9 hidden xl:block mx-auto"
                :onClick="() => $emit('action')" />
        </div>
    </div>
    <div class="w-full mx-auto -mt-10">
        <ContactForm :title="formTitle" :forms="forms" :btnText="formBtnText" @onClick="formAction" ref="formRef"/>
    </div>

</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Button from '../../../atoms/Button/Button.vue'
import ContactForm from '../../FormLayouts/SimpleContactFormWithOverlappedImages/SimpleContactFormWithOverlappedImages.vue'
import { ref } from "vue";
/**
 - Use it to show a footer section with images and contact form
 **/
export default {
    components: {
        Text,
        Button,
        ContactForm
    },
    props: {
        /**
         * title
         */
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        btnText: {
            type: String,
            default: ''
        },
        action: {
            type: Function,
            default: () => { }
        },
        formTitle: {
            type: String,
            default: ''
        },
        forms: {
            type: Array,
            default: () => []
        },
        formBtnText: {
            type: String,
            default: ''
        },
        saveContactRequest: {
            type: Function,
            default: () => { }
        }
    },
    setup(props) {
        const formRef = ref();
        const formAction = () => {
            props.saveContactRequest(formRef.value.getFormObject())
        };
        const setFormObject = (value) => {
            formRef.value.setFormObject(value)
        };
        return {
            formRef,
            formAction,
            setFormObject
        };
    }
};
</script>

<style>
    .custom-bg {
        background: url(../../../../assets/public/images/green-background.png) no-repeat 50%;
        background-size: cover;
    }
</style>
