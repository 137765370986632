<template>
  <div class="w-full mx-auto items-center relative">
    <img class="absolute w-full h-72" src="../../../../assets/public/images/start-yellow-bg.png" style="z-index: -1;"/>
    <div class="max-w-4xl mx-auto z-10 py-10 sm:py-16 px-4 sm:px-0">
      <Text :content="title" size="3xl" weight="medium" color="white" class="sm:text-4xl text-center"/>
      <Button variant="primary" :content="btnText"
              class="uppercase px-16 py-5 font-semibold text-lg shadow-lg bg-white text-yellow-400 mt-9 hidden md:block mx-auto"
              :onClick="() => $emit('action')"/>
      <!-- <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mx-auto mt-40 md:mt-10 px-4 md:px-0">
          <WithOverlapBanner v-for="card in cards" :key="card.title" v-bind="card"/>
      </div> -->
    </div>
  </div>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Button from '../../../atoms/Button/Button.vue'
import WithOverlapBanner from '../../../molecules/Cards/WithOverlapBanner/WithOverlapBanner.vue';

/**
 - Use it to show a content with title, button and cards
 **/
export default {
  components: {
    Text,
    Button,
    WithOverlapBanner
  },
  props: {
    /**
     * title
     */
    title: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    action: {
      type: Function,
      default: () => {
      }
    },
    cards: {
      type: Array,
      default: () => []
    }
  }
};
</script>
