
<template>
	<div class="flex justify-center relative max-w-xl bg-white rounded-lg">
		<img src="../../../../assets/public/images/clg-block-bg-1.png" class="w-full absolute bottom-0 left-0"/>
		<CardContainer class="w-full border border-primary shadow-lg px-4 py-1 z-20 bg-opacity-0">
				<Text :content="title" size="4xl" weight="bold" color="primary"/>
				<Text :content="description" size="2xl" weight="medium" color="gray-800" class="mt-2"/>
		</CardContainer>
	</div>
</template>

<script>
	import CardContainer from '../../../atoms/Panels/BasicCard/BasicCard.vue'
	import Text from '../../../atoms/Text/Text.vue'
	/**
	 * - Use it to show card with title, description and overlapped avatar
	 */
	export default {
		components: {
			CardContainer,
			Text,
		},

		props: {
			/**
			 * title
			 */
			title: {
				type: String,
				default: ''
			},
			/**
			 * description
			 */
			description: {
				type: String,
				default: ''
			},
			/**
			 * logo type
			 */
			logoType: {
				type: String,
				default: ''
			}
		}
	}
</script>
