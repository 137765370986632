<template>
  <div class="w-full flex justify-center items-center py-20">
    <div class="bg-white max-w-7xl flex flex-col items-center justify-center w-full">
      <Text :content="title" size="3xl" weight="semibold" color="gray-600" align="center"
            class="sm:text-4xl px-4 space-y-5 w-full md:w-700"/>
      <div class="w-full bg-gray-100 sm:bg-white grid grid-cols-1 md:grid-cols-3 gap-8 mt-20 px-4 py-8 sm:py-0 xl:px-0">
        <Card v-for="card in cards" :key="card.title" v-bind="card"/>
      </div>
    </div>
  </div>
</template>

<script>
import Text from '../../../molecules/Text/TextWithTopBorderInVariousPositions/TextWithTopBorderInVariousPositions.vue'
import Card from '../../../molecules/Cards/WithOverlapImage/WithOverlapImage.vue'

/**
 - Use it to show a content with title and cards
 **/
export default {
  components: {
    Text,
    Card
  },
  props: {
    /**
     * title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * cards
     */
    cards: {
      type: Array,
      default: () => []
    }
  }
};
</script>
