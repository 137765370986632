<template>
  <div class="flex justify-center items-center bg-contact py-10 md:py-24 pt-28 lg:pt-10 px-4 xl:px-0">
    <div class="grid grid-cols-1 md:grid-cols-3 max-w-7xl mx-auto gap-6">
      <div class="col-span-1">
        <Text size="4xl" color="gray-700" weight="semibold" :content="title" class="sm:text-5xl leading-12"/>
        <div class="sm:flex space-x-2 mt-9 hidden">
          <Icon name="CheckIcon" size="5" color="primary" custom-class="flex-shrink-0 mt-1"/>
          <Text size="lg" color="gray-500" :content="description" class=""/>
        </div>
        <div class="sm:flex space-x-2 mt-4 hidden">
          <Icon name="CheckIcon" size="5" color="primary" custom-class="flex-shrink-0 mt-1"/>
          <Text size="lg" color="gray-500" :content="description1" class=""/>
        </div>
        <div class="flex space-x-4">
          <Button variant="primary" :content="state.currentUser?.id ? 'My Dashboard': btnText" :class="`uppercase px-8 h-16 font-semibold text-lg shadow-lg ${state.currentUser?.id ? 'bg-primary': 'bg-yellow-400'} mt-12 hidden md:block`" :onClick="() => $emit('action')" />
          <div v-if="!state.currentUser?.id" class="flex flex-col justify-center items-center">
            <Button variant="secondary" :content="'Sign In'" class="uppercase px-12 h-16 font-semibold text-lg shadow-lg mt-12 hidden md:block" :onClick="() => $emit('onSignIn')" />
            <Text size="xs" color="black" content="For School District Students" custom-class="mt-2 hidden md:block"/>
          </div>

        </div>

      </div>
      <div class="col-span-1 md:col-span-2 pl-10">
        <Vue3Lottie :animationData="animationData"/>
      </div>
      <Text size="lg" color="gray-500" :content="description" class="mt-6 block md:hidden"/>
      <Button variant="primary" :content="btnText" class="uppercase px-12 py-5 font-semibold text-lg shadow-lg bg-yellow-400 mt-2 block md:hidden" :onClick="() => $emit('action')" />
      <div v-if="!state.currentUser?.id" class="flex flex-col justify-center items-center w-full">
        <Button variant="secondary" :content="'Sign In'" class="uppercase px-12 h-16 font-semibold text-lg shadow-lg block md:hidden w-full" :onClick="() => $emit('onSignIn')" />
        <Text size="xs" color="black" content="For School District Students" custom-class="mt-2 block md:hidden "/>
      </div>
    </div>
  </div>

</template>
<script>
import Icon from '../../../atoms/Icons/Icons.vue'
import Text from '../../../atoms/Text/Text.vue'
import Button from '../../../atoms/Button/Button.vue'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import animationData from "../../../../assets/public/lottie/landing/animationData.json";

/**
 * - Use it to show a simple branded header with title, description, button and logo
 */
export default {
  components: {
   Icon, Text, Button, Vue3Lottie
  },
  data() {
    return {
      animationData
    }
  },
  props: {
    /**
     * title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * description
     */
    description: {
      type: String,
      default: ''
    },
    description1: {
      type: String,
      default: ''
    },
    /**
     * button text
     */
    btnText: {
      type: String,
      default: ''
    },
    /**
     * button action
     */
    action: {
      type: Function,
      default: () => {}
    }
  },
};
</script>
